<template>
  <Dialog
    :commonDialog="visitDetailDialog"
    :dialogWidth="dialogWidth"
    v-if="getPermission('visit:view')"
  >
    <template v-slot:title>
      <v-row>
        <v-col md="8">
          Visit #{{ visitInformation.barcode }} | Job #{{ detail.barcode }}
        </v-col>
        <v-col md="4" :class="{ 'text-right': visitInformation.status }">
          <v-layout class="justify-end">
            <v-btn
              v-if="visitInformation.status == 4"
              class="mx-2 custom-grey-border custom-bold-button text-white"
              color="light-green darken-1"
            >
              <v-icon left>mdi-check-decagram</v-icon> Completed
            </v-btn>
            <template v-if="false">
              <template
                v-if="
                  getPermission('visit:update') || getPermission('visit:delete')
                "
              >
                <v-menu
                  transition="slide-y-transition"
                  bottom
                  content-class="custom-menu-list"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      class="mx-2 custom-bold-button white--text"
                      color="cyan"
                      v-bind="attrs"
                      v-on="on"
                    >
                      More Action... <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-if="getPermission('visit:update')">
                      <!-- <v-list-item
                        :disabled="!visitInformation.has_closer"
                        link
                        v-on:click="markAsComplete"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                          <v-icon class="icon-default-blue"
                            >mdi-check-all</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-title class="font-weight-500 font-size-14"
                          >Mark as Complete</v-list-item-title
                        >
                      </v-list-item> -->
                      <v-list-item link v-on:click="updateFutureVisit">
                        <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                          <v-icon class="icon-default-blue"
                            >mdi-truck-fast-outline</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-title class="font-weight-500 font-size-14"
                          >Update Future Visits</v-list-item-title
                        >
                      </v-list-item>
                    </template>
                    <template v-if="false && getPermission('visit:delete')">
                      <v-list-item link v-on:click="visitDeleteDialog = true">
                        <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                          <v-icon class="icon-default-blue">mdi-delete</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title class="font-weight-500 font-size-14"
                          >Delete</v-list-item-title
                        >
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </template>
            </template>
            <v-btn
              v-if="visitInformation.status == 1"
              :disabled="pageLoading || apptFormLoading"
              class="mx-2 custom-grey-border custom-bold-button text-white"
              color="cyan"
              v-on:click="appointmentUpdateDialog = true"
            >
              Re-Schedule
            </v-btn>
            <v-btn
              :disabled="pageLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="closeVisit"
            >
              Close
            </v-btn>
          </v-layout>
        </v-col>
      </v-row>
    </template>
    <template v-slot:body>
      <!-- <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="max-height: 65vh; position: relative"
      > -->
      <v-container>
        <v-row>
          <v-col md="6" class="custom-border-right">
            <p
              class="m-0 pb-4 font-size-18 font-weight-600 custom-nowrap-ellipsis-two-line color-custom-blue"
            >
              {{ visitInformation.title }}
            </p>
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  v-if="lodash.isEmpty(customer) === false"
                  class="py-1 px-0 max-content-width"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-list-item-icon class="margin-auto-zero mr-4">
                    <v-icon class="font-size-28">mdi-account</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="p-0">
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <v-list-item-title
                      v-else
                      class="custom-nowrap-ellipsis text-capitalize font-size-18 font-weight-500"
                    >
                      <router-link
                        :to="
                          getDefaultRoute('customer.detail', {
                            params: {
                              id: customer.customer,
                            },
                          })
                        "
                        >{{ detail.attention }}</router-link
                      >
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action
                    link
                    class="m-0 ml-4"
                    v-on:click="
                      $router.push(
                        getDefaultRoute('customer.detail', {
                          params: {
                            id: customer.customer,
                          },
                        })
                      )
                    "
                  >
                    <v-icon class="color-custom-blue"
                      >mdi-link mdi-rotate-135</v-icon
                    >
                  </v-list-item-action>
                </v-list-item>
              </template>
              <span>Attention</span>
            </v-tooltip>
          </v-col>
          <v-col md="6">
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  class="py-1 max-content-width"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-list-item-icon class="margin-auto-zero mr-4">
                    <v-icon class="font-size-28">mdi-calendar</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="p-0">
                    <v-list-item-title class="font-size-18 font-weight-500">
                      <a
                        href="javascript:void(0)"
                        v-on:click="downloadVisit"
                        class="custom-nowrap-ellipsis-two-line text-uppercase"
                        >{{ getAppointmentText }}</a
                      >
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <span>Click here to download Appointment</span>
            </v-tooltip>
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  v-if="contactPerson"
                  class="py-1 max-content-width"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-list-item-icon class="margin-auto-zero mr-4">
                    <v-icon class="font-size-28">mdi-phone</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="p-0">
                    <v-list-item-title
                      class="custom-nowrap-ellipsis text-lowercase font-size-18 font-weight-500"
                    >
                      <a :href="'tel:' + contactPerson.primary_phone">{{
                        contactPerson.primary_phone
                      }}</a>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <span>Contact Phone</span>
            </v-tooltip>
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item class="py-1" v-bind="attrs" v-on="on">
                  <v-list-item-icon class="margin-auto-zero mr-4">
                    <v-icon class="font-size-28">mdi-home-map-marker</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="p-0">
                    <v-list-item-title
                      class="custom-nowrap-ellipsis-two-line font-size-18 font-weight-500"
                    >
                      <a
                        target="_blank"
                        :href="'https://maps.google.com/?q=' + PropertyDetail"
                        >{{ PropertyDetail }}</a
                      >
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <span>Property Address</span>
            </v-tooltip>
          </v-col>
          <v-col>
            <v-tabs
              v-model="visitTab"
              background-color="transparent"
              centered
              color="cyan"
              fixed-tabs
              class="custom-tab-transparent tab-sticky"
            >
              <v-tab
                class="font-size-16 font-weight-500 color-custom-blue"
                :href="'#tab-info'"
              >
                <v-icon color="grey darken-4" left></v-icon> Information
              </v-tab>
              <template
                v-if="
                  getPermission('visit-notes:view') ||
                  getPermission('visit-notes:create')
                "
              >
                <v-tab
                  class="font-size-16 font-weight-500 color-custom-blue"
                  :href="'#tab-notes'"
                >
                  <v-icon color="grey darken-4" left></v-icon> Notes
                </v-tab>
              </template>
            </v-tabs>
            <v-tabs-items class="visit-dialog-tab" v-model="visitTab">
              <v-tab-item :value="'tab-info'">
                <v-container>
                  <v-row>
                    <v-col md="6" class="py-0 pb-2">
                      <h4
                        class="font-size-16 font-weight-600 color-custom-blue"
                      >
                        Instructions
                      </h4>
                    </v-col>
                  </v-row>
                  <div class="visit-dialog-information">
                    <template v-if="visitInformation.instruction">
                      <read-more
                        class="custom-read-more mt-2 font-weight-600 font-size-16 custom-gray-color"
                        more-str="read more"
                        :text="visitInformation.instruction"
                        link="#"
                        less-str="read less"
                        :max-chars="300"
                      >
                      </read-more>
                    </template>
                    <em v-else>No additional instructions</em>
                  </div>
                </v-container>
                <template v-if="getPermission('visit-team:view')">
                  <v-container class="custom-border-top">
                    <v-row>
                      <v-col md="6" class="py-0 pb-2">
                        <h4
                          class="font-size-16 font-weight-600 color-custom-blue"
                        >
                          Team
                        </h4>
                      </v-col>
                      <template v-if="getPermission('visit-team:create')">
                        <v-col
                          v-if="visitInformation.status == 1"
                          md="6"
                          class="text-right p-0 pb-2"
                        >
                          <v-btn
                            class="white--text"
                            color="cyan"
                            small
                            v-on:click="addTeam = true"
                            ><v-icon small left>mdi-plus</v-icon
                            ><strong class="font-size-14">Add</strong>
                          </v-btn>
                        </v-col>
                      </template>
                    </v-row>
                    <div class="visit-dialog-team">
                      <div
                        v-if="
                          lodash.isEmpty(visitInformation.engineers) === false
                        "
                      >
                        <template
                          v-for="(data, index) in visitInformation.engineers"
                        >
                          <v-list-item :key="index">
                            <v-list-item-avatar size="50">
                              <v-img
                                :lazy-src="$defaultProfileImage"
                                :src="$assetAPIURL(data.engineer.profile_logo)"
                              ></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content
                              class="px-6 py-2 custom-border-left custom-border-right"
                            >
                              <v-list-item-title
                                class="font-size-16 font-weight-500 custom-nowrap-ellipsis"
                                v-html="data.engineer.display_name"
                              ></v-list-item-title>
                              <v-list-item-subtitle
                                class="font-size-16 font-weight-500 custom-nowrap-ellipsis"
                                v-html="data.engineer.primary_email"
                              ></v-list-item-subtitle>
                              <v-list-item-subtitle
                                class="font-size-16 font-weight-500 custom-nowrap-ellipsis"
                                v-html="data.engineer.primary_phone"
                              ></v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action
                              v-if="visitInformation.status == 1"
                            >
                              <v-btn
                                icon
                                large
                                v-on:click="deleteEngineerConfirm(data)"
                              >
                                <v-icon color="red lighten-1"
                                  >mdi-delete</v-icon
                                >
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                          <v-divider
                            v-if="visitInformation.engineers[index + 1]"
                            inset
                            :key="'divider' + index"
                          ></v-divider>
                        </template>
                      </div>
                      <em v-else class="line-height-3 py-4 text--secondary"
                        >Not assigned yet.</em
                      >
                    </div>
                  </v-container>
                </template>
                <v-container class="custom-border-top">
                  <v-row>
                    <v-col md="6" class="py-0 pb-2">
                      <h4
                        class="font-size-16 font-weight-600 color-custom-blue"
                      >
                        Reminders
                      </h4>
                    </v-col>
                  </v-row>
                  <div class="visit-dialog-reminder">
                    <em class="line-height-3 py-4 text--secondary"
                      >No reminders found.</em
                    >
                  </div>
                </v-container>
              </v-tab-item>
              <v-tab-item :value="'tab-notes'">
                <template v-if="getPermission('visit-notes:create')">
                  <template v-if="visitInformation.status == 1">
                    <v-form
                      ref="visitNoteForm"
                      v-model.trim="formValid"
                      lazy-validation
                      v-on:submit.stop.prevent="createVisitNote"
                    >
                      <v-container fluid>
                        <v-card
                          flat
                          class="custom-grey-border remove-border-radius"
                        >
                          <v-card-text class="p-6 font-size-16">
                            <v-textarea
                              v-model.trim="visitNote"
                              :rules="
                                visitNoteActive
                                  ? [() => !!visitNote || 'Notes is required']
                                  : []
                              "
                              auto-grow
                              dense
                              filled
                              flat
                              :disabled="pageLoading"
                              label="Notes"
                              solo
                              hide-details
                              row-height="20"
                              :readonly="!visitNoteActive"
                              v-on:dblclick="visitNoteActive = true"
                            ></v-textarea>
                            <div v-if="visitNoteActive" class="mt-4">
                              <FileTemplate
                                isMinDisplay
                                v-on:file:updated="updateFiles"
                                :allowUpload.sync="visitNoteActive"
                              ></FileTemplate>
                            </div>
                            <div
                              v-if="visitNoteActive"
                              class="text-right custom-border-top py-4 mt-4"
                            >
                              <v-btn
                                class="mx-2 custom-bold-button white--text"
                                color="cyan"
                                :loading="formLoading"
                                :disabled="!formValid || formLoading"
                                v-on:click="createVisitNote"
                              >
                                Save
                              </v-btn>
                              <v-btn
                                :disabled="formLoading"
                                class="custom-grey-border custom-bold-button"
                                v-on:click="closeNote"
                              >
                                Cancel
                              </v-btn>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-container>
                    </v-form>
                  </template>
                </template>
                <template v-if="getPermission('visit-notes:view')">
                  <v-container fluid>
                    <template v-if="visitNotes.length > 0">
                      <v-card
                        flat
                        class="custom-grey-border remove-border-radius mb-4"
                        v-for="(note, index) in visitNotes"
                        :key="index"
                      >
                        <v-card-title
                          class="gray-background custom-border-bottom py-0"
                          :key="'visit-note-card-title' + index"
                        >
                          <v-row>
                            <v-col md="1" cols="2" class="text-center">
                              <v-avatar color="cyan">
                                <span class="white--text headline">{{
                                  getShortName(note.added_by.display_name)
                                }}</span>
                              </v-avatar>
                            </v-col>
                            <v-col md="11" cols="10">
                              <v-list-item-title
                                class="font-size-16 font-weight-500"
                                v-html="note.added_by.display_name"
                              ></v-list-item-title>
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-list-item-subtitle
                                    class="font-size-14 max-content-width"
                                    v-html="note.created_at"
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-list-item-subtitle>
                                </template>
                                <p class="m-0 custom-nowrap-ellipsis">
                                  {{ formatDateTime(note.added_at) }}
                                </p>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <v-card-text
                          :key="'visit-note-card-text-0' + index"
                          class="pt-4"
                        >
                          <read-more
                            class="custom-grey-border custom-read-more px-4 py-4 font-weight-600 font-size-16 custom-gray-color"
                            more-str="read more"
                            :text="
                              lodash.isEmpty(note) === false ? note.notes : ''
                            "
                            link="#"
                            less-str="read less"
                            :max-chars="200"
                          >
                          </read-more>
                        </v-card-text>
                        <v-card-text
                          v-if="lodash.isEmpty(note.documents) === false"
                          :key="'visit-note-card-text-1' + index"
                          class="pt-0"
                        >
                          <FileTemplate
                            isMinDisplay
                            :attachments.sync="note.documents"
                            v-on:file:updated="updateFiles"
                          ></FileTemplate>
                        </v-card-text>
                      </v-card>
                    </template>
                    <v-card
                      flat
                      v-else
                      class="custom-grey-border remove-border-radius mb-4"
                    >
                      <em class="line-height-3 px-4 py-4 text--secondary"
                        >No notes found.</em
                      >
                    </v-card>
                  </v-container>
                </template>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
      <!-- </perfect-scrollbar> -->
      <!-- Reschedule Appointment:begin -->
      <Dialog :commonDialog="appointmentUpdateDialog">
        <template v-slot:title>Reschedule Appointment</template>
        <template v-slot:body>
          <v-container fluid class="py-0">
            <v-row>
              <v-col md="4" class="py-1 font-weight-600"> Date </v-col>
              <v-col md="4" class="py-1 font-weight-600"> Start Time </v-col>
              <v-col md="4" class="py-1 font-weight-600"> End Time </v-col>
              <v-col md="4" class="py-1">
                <Datepicker
                  :defaultDate.sync="updateStartDate"
                  solo
                  v-on:update:date-picker="updateAppointmentDate"
                ></Datepicker>
              </v-col>
              <v-col md="4" class="py-1">
                <Timepicker
                  :defaultTime.sync="updateStartTime"
                  solo
                  v-on:update:time-picker="updateAppointmentStartTime"
                ></Timepicker>
              </v-col>
              <v-col md="4" class="py-1">
                <Timepicker
                  :defaultTime.sync="updateEndTime"
                  solo
                  v-on:update:time-picker="updateAppointmentEndTime"
                ></Timepicker>
              </v-col>
              <v-col md="12" class="py-2">
                <em
                  >All engineers will be removed from visit#
                  {{ visitInformation.barcode }}</em
                >
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="apptFormLoading || pageLoading"
            :loading="apptFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="updateAppointment"
          >
            Save
          </v-btn>
          <v-btn
            :disabled="apptFormLoading || pageLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="appointmentUpdateDialog = false"
          >
            Close
          </v-btn>
        </template>
      </Dialog>
      <!-- Reschedule Appointment:end -->
      <AddVisitTeamDialog
        v-if="addTeam"
        :visit.sync="visitInformation"
        :detail.sync="detail"
        :pageLoading.sync="pageLoading"
      ></AddVisitTeamDialog>
      <EngineerDelete
        :deleteDialog.sync="visitEngineerDeleteDialog"
        :requestUrl.sync="visitEngineerDeleteRequestURL"
        v-on:delete:success="visitEngineerDeleteSuccess"
        v-on:delete:close="visitEngineerDeleteDialog = false"
      ></EngineerDelete>
      <VisitDelete
        :deleteDialog.sync="visitDeleteDialog"
        :requestUrl.sync="visitDeleteRequestURL"
        v-on:delete:success="visitDeleteSuccess"
        v-on:delete:close="visitDeleteDialog = false"
      ></VisitDelete>
    </template>
  </Dialog>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import moment from "moment-timezone";
import Dialog from "@/view/pages/partials/Dialog.vue";
import VisitDelete from "@/view/pages/partials/Delete-Request-Template.vue";
import EngineerDelete from "@/view/pages/partials/Delete-Request-Template.vue";
import AddVisitTeamDialog from "@/view/pages/job/partials/Add-Visit-Team-Dialog.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import Datepicker from "@/view/pages/partials/Datepicker.vue";
import Timepicker from "@/view/pages/partials/Timepicker.vue";
import { PATCH, PUT, GET } from "@/core/services/store/request.module";
import { DialogJobEventBus } from "@/core/lib/job/dialog.job.lib";
import { downloadEvent } from "@/core/lib/ics.lib";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  props: {
    pageLoading: {
      type: Boolean,
      default: false,
    },
    visit: {
      type: Number,
      default: 0,
    },
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    customer: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    property: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    contactPerson: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  watch: {
    visit(param) {
      if (this.lodash.toSafeInteger(param) > 0) {
        this.getVisit(this.lodash.toSafeInteger(param));
      }
    },
  },
  components: {
    Dialog,
    EngineerDelete,
    VisitDelete,
    AddVisitTeamDialog,
    FileTemplate,
    Datepicker,
    Timepicker,
  },
  data() {
    return {
      apptFormLoading: false,
      appointmentUpdateDialog: false,
      addTeam: false,
      completeLoading: false,
      visitDeleteDialog: false,
      visitDeleteRequestURL: "",
      visitNote: null,
      visitNotes: new Array(),
      visitNoteActive: false,
      visitTab: null,
      visitDetailDialog: false,
      visitInformation: new Object(),
      visitEngineerDeleteDialog: false,
      visitEngineerDeleteRequestURL: "",
      newAttachments: new Array(),
      updateAppointmentFormData: {
        date: null,
        start_time: null,
        end_time: null,
      },
    };
  },
  methods: {
    updateAppointmentDate(param) {
      this.updateAppointmentFormData.date = param;
    },
    updateAppointmentStartTime(param) {
      this.updateAppointmentFormData.start_time = param;
    },
    updateAppointmentEndTime(param) {
      this.updateAppointmentFormData.end_time = param;
    },
    updateAppointment() {
      const _this = this;
      _this.apptFormLoading = true;

      _this.$store
        .dispatch(PATCH, {
          url:
            "job/" +
            _this.detail.id +
            "/visit/" +
            _this.visitInformation.id +
            "/re-schedule",
          data: {
            date: _this.updateAppointmentFormData.date
              ? moment(_this.updateAppointmentFormData.date).format(
                  "YYYY-MM-DD"
                )
              : moment().format("YYYY-MM-DD"),
            start_time: _this.updateAppointmentFormData.start_time
              ? moment(_this.updateAppointmentFormData.start_time, [
                  "h:mm A",
                ]).format("HH:mm")
              : moment().startOf("day").format("HH:mm"),
            end_time: _this.updateAppointmentFormData.end_time
              ? moment(_this.updateAppointmentFormData.end_time, [
                  "h:mm A",
                ]).format("HH:mm")
              : moment().endOf("day").format("HH:mm"),
          },
        })
        .then(() => {
          _this.appointmentUpdateDialog = false;
          _this.$emit("schedule:updated", true);
          DialogJobEventBus.$emit("load:visit", true);
          DialogJobEventBus.$emit("load:job", true);
          _this.closeVisit();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.apptFormLoading = false;
        });
    },
    updateFiles(param) {
      this.newAttachments = param;
    },
    closeNote() {
      this.formValid = true;
      this.visitNote = null;
      this.visitNoteActive = false;
    },
    visitDeleteSuccess() {
      this.closeVisit();
      this.visitDeleteDialog = false;
      DialogJobEventBus.$emit("load:job", true);
    },
    visitEngineerDeleteSuccess() {
      this.getVisit(this.visit);
      this.visitEngineerDeleteDialog = false;
      DialogJobEventBus.$emit("load:job", true);
    },
    markAsComplete() {
      const _this = this;
      _this.completeLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url:
            "job/" +
            _this.detail.id +
            "/visit/" +
            _this.visitInformation.id +
            "/update-status/1",
        })
        .then(() => {
          DialogJobEventBus.$emit("load:job", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.getVisit(_this.visit);
          _this.completeLoading = false;
        });
    },
    updateFutureVisit() {
      const _this = this;
      _this.$store
        .dispatch(PATCH, {
          url:
            "job/" +
            _this.detail.id +
            "/visit/" +
            _this.visitInformation.id +
            "/update-future",
        })
        .then(() => {
          DialogJobEventBus.$emit("load:job", true);
          _this.closeVisit();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.getVisit(_this.visit);
        });
    },
    createVisitNote() {
      const _this = this;

      if (!_this.$refs.visitNoteForm.validate()) {
        return false;
      }
      _this.formLoading = true;

      _this.$store
        .dispatch(PUT, {
          url:
            "job/" +
            _this.detail.id +
            "/visit/" +
            _this.visitInformation.id +
            "/note",
          data: {
            notes: _this.visitNote,
            documents: _this.newAttachments,
          },
        })
        .then(() => {
          _this.getVisit(_this.visit);
          _this.closeNote();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    downloadVisit() {
      try {
        let startDateArray = moment(this.visitInformation.started_at)
          .format("YYYY-MM-DD")
          .split("-");
        let startTimeArray = moment(this.visitInformation.started_at)
          .format("HH:mm")
          .split(":");
        let endDateArray = moment(this.visitInformation.finished_at)
          .format("YYYY-MM-DD")
          .split("-");
        let endTimeArray = moment(this.visitInformation.finished_at)
          .format("HH:mm")
          .split(":");
        if (startTimeArray[0] == "00") {
          startTimeArray = [];
        }
        const visit = {
          start: this.lodash.concat(startDateArray, startTimeArray),
          end: this.lodash.concat(endDateArray, endTimeArray),
          title: this.visitInformation.title,
          description: this.visitInformation.instruction,
          location: this.PropertyDetail,
          url: process.env.VUE_APP_BASE_URL,
          geo: { lat: this.property.latitude, lon: this.property.longitude },
          categories: this.detail.ticket_type,
          status: "CONFIRMED",
          organizer: {
            name: this.detail.added_by.display_name,
            email: this.detail.added_by.user_email,
          },
          attendees: this.visitInformation.engineers.map((row) => {
            if (row.engineer) {
              return {
                name: row.engineer.full_name,
                email: row.engineer.primary_email,
              };
            }
          }),
        };
        downloadEvent(visit);
      } catch (error) {
        ErrorEventBus.$emit("update:error", InitializeError(error));
      }
    },
    getVisit(id) {
      const _this = this;
      let requestUrl = "job/" + _this.detail.id + "/visit/" + id;
      _this.$store
        .dispatch(GET, { url: requestUrl })
        .then(({ data }) => {
          _this.visitInformation = data;
          _this.visitNotes = data.notes;
          _this.visitDetailDialog = true;
          _this.visitDeleteRequestURL = requestUrl;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          DialogJobEventBus.$emit("loader:visit-row", false);
        });
    },
    closeVisit() {
      this.visitDetailDialog = false;
      setTimeout(function () {
        DialogJobEventBus.$emit("close:single-visit-detail-dialog", true);
      }, 500);
    },
    deleteEngineerConfirm(data) {
      const _this = this;
      _this.visitEngineerDeleteRequestURL =
        "job/" +
        _this.detail.id +
        "/visit/" +
        _this.visit +
        "/engineer/" +
        data.id;
      _this.visitEngineerDeleteDialog = true;
    },
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 65);
    },
    PropertyDetail() {
      let propertyArr = new Array();
      if (this.lodash.isEmpty(this.property) === false) {
        if (this.property.unit_no) {
          propertyArr.push(this.property.unit_no);
        }
        if (this.property.street_1) {
          propertyArr.push(this.property.street_1);
        }
        if (this.property.street_2) {
          propertyArr.push(this.property.street_2);
        }
        if (this.property.zip_code) {
          propertyArr.push(this.property.zip_code);
        }
        if (this.CityName) {
          propertyArr.push(this.CityName);
        }
        if (this.StateName) {
          propertyArr.push(this.StateName);
        }
        if (this.CountryName) {
          propertyArr.push(this.CountryName);
        }
      }
      return propertyArr.join(", ");
    },
    CityName() {
      return this.lodash.isEmpty(this.property.city) === false
        ? this.property.city.name
        : null;
    },
    StateName() {
      return this.lodash.isEmpty(this.property.state) === false
        ? this.property.state.name
        : null;
    },
    CountryName() {
      return this.lodash.isEmpty(this.property.country) === false
        ? this.property.country.name
        : null;
    },
    getAppointmentText() {
      if (this.visitInformation) {
        let date = this.formatDate(this.visitInformation.started_at);
        let startTime = moment(this.visitInformation.started_at).format(
          "hh:mm A"
        );
        let endTime = moment(this.visitInformation.finished_at).format(
          "hh:mm A"
        );
        return date + " " + startTime + " - " + endTime;
      }
      return null;
    },
    updateStartDate() {
      if (this.lodash.isEmpty(this.visitInformation) === false) {
        return moment(this.visitInformation.started_at).format("YYYY-MM-DD");
      }
      return null;
    },
    updateStartTime() {
      if (this.lodash.isEmpty(this.visitInformation) === false) {
        return moment(this.visitInformation.started_at).format("hh:mm A");
      }
      return null;
    },
    updateEndTime() {
      if (this.lodash.isEmpty(this.visitInformation) === false) {
        return moment(this.visitInformation.finished_at).format("hh:mm A");
      }
      return null;
    },
  },
  beforeDestroy() {
    /*DialogJobEventBus.$off("close:visit-team-dialog");
    DialogJobEventBus.$off("load:visit-content");*/
  },
  created() {
    const _this = this;

    DialogJobEventBus.$on("close:visit-team-dialog", function (argument) {
      if (argument) {
        _this.addTeam = false;
        DialogJobEventBus.$emit("load:job", true);
      }
    });

    DialogJobEventBus.$on("load:visit-content", function () {
      _this.getVisit(_this.visit);
    });
  },
  mounted() {
    const _this = this;
    _this.getVisit(_this.visit);
  },
};
</script>
